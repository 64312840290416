@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  font-family: 'Montserrat', sans-serif !important
}

#loginPage {
  min-height: 100vh;
  background-color: white;
  color: #1C2E4A;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#loginPageLanguageToggle {
  font-size: 16px;  
  color: #868686;
  font-weight: 500;
  text-align: right;
  padding-top: 1rem;
  padding-right: 2rem;
  cursor: pointer;
  margin-bottom: 0px;
}

#loginPageLanguageToggle:hover {  
  color: #6b64ff;
}

#loginPage .loginContainer{
  width: 100%;
  padding-left: 35%;
  padding-right: 35%;
  margin-top: -40px;
}

#loginPage img{
  margin-bottom: 4rem;
}

#loginPage .primaryBtn{
  margin-bottom: 1rem;
}

p.inputLabel {
  margin-bottom: 0.25rem !important;
  color: #111111 !important;
  font-weight: 500 !important;
}

input.authInput {
  border: 1px solid #d2d2d7;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  outline: none;
  height: 40px;
  width: 100%;
}

input.authInput::placeholder {
  color: #86868B;
}

a.authLink {
  color: #6b64ff
}

.authLink {
  color: #6b64ff;
  text-decoration: underline;
  cursor: pointer;
}

button.primaryBtn {
  background-color: #6b64ff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

button.primaryBtn:hover {
  background-color: #6b64ff;
}

button.secondaryBtn {
  background-color: #322e87;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

button.secondaryBtn:hover {
  background-color: #322e87;
}

#myNavbar {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#myNavbar a.navLink {
  text-decoration: none;
  font-size: 16px;
  margin-left: 1.5rem;
  color: #868686;
  font-weight: 500;
}

#myNavbar a.navLink:hover {
  text-decoration: none;
  font-size: 16px;
  color: #6b64ff;
  font-weight: 500;
}

#createOrderBtn {
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #6b64ff;
  color: #fff;
  margin-left: 1.5rem;
}

.css-b62m3t-container {
  margin-bottom: 0.5rem;
}

.css-13cymwt-control {
  border-radius: 10px !important;
}

.css-t3ipsp-control {
  border-radius: 10px !important;
  border-color: #6b64ff !important;
  box-shadow: none !important;
}

#ordersPage .primaryBtn {
  margin-bottom: 1rem;
}

.tabsContainer {
  margin-bottom: 0.5rem;
}

.tabsContainer button{
  width: 50%;
  border: 3px solid #322e87;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  background-color: white;
  color: #322e87;
}

.tabsContainer button.active{
  background-color: #322e87;
  color: white;
}

.tabsContainer button.leftTab{
  border-right: 1.5px solid #322e87;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tabsContainer button.rightTab{
  border-left: 1.5px solid #322e87;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#ordersPage .ordersListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

#ordersPage .ordersListItem p{
  margin-bottom: 0px;
  color: #868686;
  font-weight: 400;
}

#orderNumber {
  margin-right: 0.5rem;
}

#orderItemsQuantity {
  background-color: #322e87;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#orderItemsQuantity p{
  color: white !important;
}

#pageContainer h2 {
  color: #322e87;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

#acceptOrderBtn {
  width: 20%;
}

#ordersListHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

#ordersListHeader p{
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
}

.orderItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 1rem;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
}

.orderItemContainer img{
  width: 50px;
  height: 50px;
}

.orderItemContainer p{
  margin-bottom: 0px !important;
}

.orderItemImage {
  width: 5%;
}

.orderItemName {
  width: 12.5%;
  text-align: center;
}

.orderItemBrand {
  width: 12.5%;
  text-align: center;
}

.orderItemPriceUnit {
  width: 12.5%;
  text-align: center;
}

.orderItemQuantity {
  width: 12.5%;
  text-align: center;
}

.orderItemUnit {
  width: 12.5%;
  text-align: center;
}

.orderItemPriceTotal {
  width: 12.5%;
  text-align: center;
}

.orderItemStatus {
  width: 12.5%;
  text-align: center;
}

#pageContainer p#singleOrderTotal {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: right;
  color: #322e87;
  font-size: 20px;
  font-weight: 700;
}

p#newItemTotal {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: right;
  color: #322e87;
  font-size: 20px;
  font-weight: 700;
}

#myNavbarLogoRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 1rem;
}

#myNavbarLogoRow div {
  width: 33.33%;
}

#myNavbarLogoDiv {
  text-align: center;
}

#myNavbarSocialsDiv {
  display: flex;
  align-items: center;
  justify-content: right;
}

#myNavbarSocialsDiv svg{
  margin-left: 1rem;
  height: 20px;
  width: auto;
  color: #6b64ff;
}

#myNavbarMenuRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #e2e2e2;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#myNavbarMenuRow a{
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: #111111;
}

/* #loginPage button{
  background-color: #1974d2;
  border-radius: 0px;
  border: none;
  width: 100%;
  padding: 0.75rem 1rem;
} */

#heroSection {
  background-color: white;
  min-height: 100vh;
}

nav.navbar {
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  background-color: #6b64ff;
  justify-content: center;
  text-align: center;
}

nav.navbar .container-fluid{
  display: block;
}

nav.navbar .navbar-brand {
  margin-right: 0px;
}

nav.navbar svg {
  width: 20px;
  height: auto;
}

nav.navbar #navbarServicesItemIcon {
  margin-right: 0.5rem;
}

nav.navbar .chevronDown {
  width: 15px;
  height: auto;
  margin-left: 0.25rem;
}

nav.navbar a{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 500;
  font-size: 16px;
  color: white !important;
  text-decoration: none;
}

nav.navbar p{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 500;
  font-size: 16px;
  color: white !important;
  text-decoration: none;
}

nav.navbar a#registerButton {
  color: #1C2E4A !important;
  background-color: white;
  padding: 11px 11px;
  border-radius: 0px;
  margin-left: 0.5rem;
}

#pageContainer .sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#pageContainer .sectionHeader h1{
  color: #6b64ff;
}

#pageContainer .sectionHeader h1 svg{
  color: #6b64ff;
  height: 30px;
  width: 30px;
  margin-top: -3px;
  margin-left: -7px;
  cursor: pointer;
}

#pageContainer .sectionHeader button{
  background-color: #1974d2;
  border: none;
  padding: 11px;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 500;
}

#pageContainer .sectionHeader button svg{
  width: 20px;
  height: auto;
  margin-top: -2.5px;
  margin-right: 0.5rem;
}

#pageContainer h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 1rem;
}

#pageContainer p {
  color: #868686;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3rem;
}

#pageContainer .card {
  background-color: white;
  border: none;
  border-radius: 0px;
  padding: 0.75rem 1rem;
}

#pageContainer .card .cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#pageContainer .card .cardHeader svg {
  color: #1C2E4A;
  height: 24px;
  width: auto;
}

#pageContainer #heroButtonsContainer {
  display: flex;
  flex-direction: row;
}

#pageContainer #heroButtonsContainer .btn{
  background-color: #1C2E4A;
  border-color: #1C2E4A;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 700;
  padding: 17px 40px;
}

#pageContainer #heroButtonsContainer .btnAlt {
  background-color: white;
  color: #1C2E4A;
  border-color: white;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 500;
  padding: 11px 11px;
}

#pageContainer #widgetRequestButton {
  color: #1974d2;
  font-size: 16px;
  font-weight: 500;
  margin-top: 1rem;
}

#howWeDoItSection {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#howWeDoItSection h1 {
  color: #1C2E4A;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 2rem;
}

#howWeDoItSection p {
  color: #111111;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

#howWeDoItSection .howWeDoItStep {
  background-color: #1974d2;
  display: flex;
  flex-direction: row;
  padding: 11px;
  margin-bottom: 1rem;
}

#howWeDoItSection .howWeDoItStep p{
  margin-bottom: 0px;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

#howWeDoItSection .howWeDoItStep p.howWeDoItStepNumber{
  font-size: 30px;
  font-weight: bolder;
  margin-right: 1rem;
}

#howWeDoItSection a {
  background-color: #1974d2;
  display: flex;
  color: white;
  justify-content: center;
  padding: 11px;
  text-decoration: none;
  margin-bottom: 1rem;
  font-weight: 500;
}

#howWeDoItSection svg {
  margin-right: 0.5rem;
  width: 20px;
  height: auto;
}

#howWeDoItSection #outreachFormInputRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#howWeDoItSection input {
  border-radius: 0px;
  padding: 10px 20px;
  outline: none;
  border: 1px solid #D2D2D7;
  width: 49.4%;
  margin-bottom: 1rem;
}

#howWeDoItSection .servicesCard {
  border: 5px solid #1C2E4A;
  text-align: center;
  padding: 2rem;
}

#howWeDoItSection .servicesCard h2{
  font-size: 25px;
  font-weight: 700;
  color: #1974d2
}

#howWeDoItSection .servicesCard p{
  margin-bottom: 0.5rem;
}

#whoWeAreSection {
  background-color: #1C2E4A;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#whoWeAreSection h1 {
  color: white;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

#whoWeAreSection p {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

#getStartedSection {
  background-color: #1C2E4A;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#getStartedSection h1 {
  color: white;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0px;
}

#getStartedSection p {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2rem;
}

#getStartedSection input {
  border-radius: 0px;
  padding: 10px 20px;
  outline: none;
  border: 1px solid white;
  background-color: #1C2E4A;
  color: white;
}

#getStartedSection input::placeholder {
  color: white;
}

#getStartedSection button {
  color: white !important;
  font-weight: 500;
  background-color: white;
  color: #1C2E4A !important;
  padding: 11px 31px;
  border-radius: 0px;
  border: none;
}

#industriesSection {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#industriesSection h1 {
  color: #1C2E4A;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0px;
}

#industriesSection p {
  color: #111111;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

#industriesSection .industriesCard {
  /* border: 5px solid #1C2E4A; */
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* padding: 2rem; */
  padding-top: 2rem;
  /* padding-bottom: 2rem; */
  /* margin-left: 0.5rem;
  margin-right: 0.5rem; */
  width: 200px;
  height: 100%;
}

#industriesSection .industriesCard svg {
  height: 50px;
  width: auto;
  margin-bottom: 1rem;
  color: #1974d2;
}

#industriesSection .industriesCard h2{
  font-size: 25px;
  font-weight: 700;
  color: #1C2E4A
}

.react-multi-carousel-list {
  max-width: none;
}

#industriesSection .react-multiple-carousel__arrow {
  margin-top: -50px;
  background: #00000015;
  display: none;
}

#ourTeamSection {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#ourTeamSection h1 {
  color: #1C2E4A;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

#ourTeamSection p {
  color: #111111;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

#ourTeamSection .ourTeamText {
  margin-bottom: 2rem;
}

#ourTeamSection .teamMemberContainer {
  margin-right: 2rem;
}

#ourTeamSection .teamMemberContainer img{
  margin-bottom: 0.5rem;
}

#ourTeamSection .teamMemberContainer h3 {
  color: #1C2E4A;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

#ourTeamSection .teamMemberContainer h4 {
  color: #1C2E4A;
  font-size: 16px;
  font-weight: 700;
  color: #1974d2
}

#ourTeamSection .teamMemberContainer p {
  color: #111111;
  font-size: 16px;
  font-weight: 400;
}

#ourTeamSection .teamMembersRow {
  display: flex;
  flex-direction: row;
}

#contactSection {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

#contactSection h1 {
  color: #1C2E4A;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

#contactSection p {
  color: #111111;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2rem;
}

#contactSection input {
  border-radius: 0px;
  padding: 10px 20px;
  outline: none;
  border: 1px solid #D2D2D7;
  width: 50%;
  margin-bottom: 1rem;
}

#contactSection input::placeholder,  #howWeDoItSection input::placeholder  {
  color: #86868B;
}

#contactSection textarea, #howWeDoItSection textarea {
  border-radius: 0px;
  padding: 10px 20px;
  outline: none;
  border: 1px solid #D2D2D7;
  width: 100%;
  margin-bottom: 1rem;
}

#contactSection textarea::placeholder, #howWeDoItSection textarea::placeholder {
  color: #86868B;
}

#contactSection input[type=submit i], #howWeDoItSection input[type=submit i] {
  color: white !important;
  font-weight: 500;
  background-color: #1974d2;
  padding: 11px 11px;
  border-radius: 0px;
  border: none;
  width: 100%;
  float: right;
  margin-bottom: 0px;
}

#phoneNumber {
  color: #1974d2;
  font-weight: 700;
}

.Toastify__toast-icon svg {
  fill: #1974d2;
}

.Toastify__progress-bar--success{
  background: #1974d2 !important;
}

footer {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer img{
  margin-bottom: 5rem;
}

footer p {
  color: #868686;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 2rem;
}

footer h3 {
  color: #111111;
  font-size: 20px;
  font-weight: 700;
}

footer a {
  color: #111111 !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #6b64ff;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp_float:hover {
  color: white;
}

.whatsapp-icon {
  margin-top: 7px;
}

#termsOfServiceContainer, #privacyPolicyContainer {
  color: #111111;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#termsOfServiceContainer h1, #privacyPolicyContainer h1 {
  color: #1C2E4A;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1rem;
}

#termsOfServiceContainer h2, #privacyPolicyContainer h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

#termsOfServiceContainer a, #privacyPolicyContainer a {
  color: white
}

#notificationsPage .notificationCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
  border: 2px solid white;
}

#notificationsPage .notificationCard.unread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
  border: 2px solid #1974d2;
  cursor: pointer;
}

#notificationsPage .notificationCard h3{
  color: #1C2E4A;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

#notificationsPage .notificationCard p{
  color: #111111;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

#notificationsPage .notificationCard p#notificationAge{
  color: #86868B;
}

.searchbar {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.searchbar input {
  border: 1px solid #d2d2d7;
  border-radius: 0px;
  font-size: 16px;
}

.searchbar input::placeholder {
  color: #86868B
}

.searchbar button {
  border-color: #1974d2;
  border-radius: 0px;
  background-color: #1974d2;
  font-size: 16px;
  padding: 11px;
}

.searchbar button:hover {
  border-color: #1974d2;
  background-color: #1974d2;
}

#helpPage .helpPageArticle {
  background-color: white;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
}
 
#helpPage .helpPageArticle h3{
  color: #1C2E4A;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

.accordion{
  --bs-accordion-border-radius: 10px;
  --bs-accordion-btn-bg: transparent;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed){
  background-color: transparent;
  color: #111111;
}

.accordion-button::after {
  margin-bottom: -0.5rem;
}

.accordion-button:not(.collapsed)::after {
  margin-top: -1rem;
}

.accordion {
  --bs-accordion-btn-icon: url("https://res.cloudinary.com/da3qtg7t5/image/upload/v1687384059/Gazindo%20Lite/accordionIconInactive.svg");
  --bs-accordion-btn-active-icon: url("https://res.cloudinary.com/da3qtg7t5/image/upload/v1687384059/Gazindo%20Lite/accordionIconInactive.svg");
}

h2.accordion-header {
  margin-bottom: 0px !important;
}

.accordion-body{
  color: #868686;
}

#helpPage #pageContainer p{
  margin-bottom: 1.5rem;
}


#languagePage .languageCard {
  background-color: white;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
}

#languagePage .languageCard p{
  color: #111111;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

#languagePage .languageCard.active {
  background-color: #1974d2;
  padding: 0.75rem 1rem;
}

#languagePage .languageCard.active p{
  color: white;
}

#accountPageCompanyCard {
  width: fit-content;
  border-radius: 10px;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1rem;
}

#accountPageCompanyCard img{
  height: 100px;
  border-radius: 10px;
}

#accountPageCompanyCardTextContainer {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

#accountPageCompanyCardTextContainer h3{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

#accountPageCompanyCardTextContainer p{
  margin-bottom: 0px;
}

#accountPage .accountPageCard {
  background-color: white;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
}

#accountPage .accountPageCard .cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#accountPage .accountPageCard .cardHeader .sectionHeaderTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#accountPage .accountPageCard .cardHeader .sectionHeaderTextContainer svg{
  width: 20px;
  height: auto;
  margin-left: 0.5rem;
  cursor: pointer;
}

#accountPage .accountPageCard .cardHeader button {
  background-color: #1974d2;
  border-color: #1974d2;
  border-radius: 0px;
  padding: 11px;
}

#accountPage .accountPageCard h3{
  color: #1C2E4A;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

#accountPage .accountPageCard .cardBody {
  padding-top: 1rem;
}

#accountPage .accountPageCard .cardBody.closed {
  display: none;
}

#accountPage .accountPageCard .accountFieldRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

#accountPage .accountPageCard .accountFieldRow p{
  color: #111111;
  font-weight: 400;
  margin-bottom: 0px;
}

#accountPage .accountPageCard .accountFieldRow p.label{
  font-weight: 700;
}

#accountPage .accountPageCard .accountFieldRow input{
  height: 40px;
  width: 50%;
  border: 1px solid #d2d2d7;
  border-radius: 0px;
}

#companyPageCompanyContainer {
  
}

#companyPageCountryCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

#companyPageCountryCard img{
  height: 100px;
  border-radius: 10px;
}

#companyPageCountryCardTextContainer {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

#companyPageCountryCard h3{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

#companyPageCountryCard p{
  margin-bottom: 0px;
  color: #868686;
}

.branchMemberCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.branchMemberCard p{
  margin-bottom: 0px;
  color: #868686;
}

.branchMemberCard div button{
  border: 2px solid #322e87;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  background-color: white;
  color: #322e87;
}

.branchMemberCard div button.active{
  background-color: #322e87;
  color: white;
}

.branchMemberCard div button.leftTab{
  border-right: 1px solid #322e87;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.branchMemberCard div button.rightTab{
  border-left: 1px solid #322e87;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.branchMemberCard svg{
  width: 20px;
  height: 20px;
  color: #322e87;
  margin-top: 10px;
  cursor: pointer;
}

.toggler{
  width: 100%;
  margin-bottom: 1rem;
}

.toggler button{
  border: 2px solid #322e87;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  background-color: white;
  color: #322e87;
}

.toggler button.active{
  background-color: #322e87;
  color: white;
}

.toggler button.leftTab{
  width: 50%;
  border-right: 1px solid #322e87;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.toggler button.rightTab{
  width: 50%;
  border-left: 1px solid #322e87;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.swal2-modal {
  border-radius: 10px;
}

.swal2-icon {
  color: #322e87 !important;
  border-color: #322e87 !important;
}

.swal2-title {
  color: #1C2E4A !important;
}

.swal2-modal button {
  border-radius: 10px !important;
}

div:where(.swal2-container) div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
  background-color: #322e87 !important;
}

#dataPage #uploadButton {
  background-color: #1974d2;
  border-radius: 0px;
  border: none;
  padding: 0.75rem 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

#dataPage #uploadButton svg{
  margin-right: 0.5rem;
  margin-top: -3px;
  width: 20px;
  height: auto;
}

#dataPage .dataItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
}

#dataPage .dataItem:hover {
  cursor: pointer;
}

#dataPage .dataItem p{
  color: #1C2E4A;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: left;
}

#dataPage .dataItem svg{
  width: 20px;
  height: auto;
  color: #86868B;
  z-index: 1000;
}

#dataPage .dataItem p.dataItemSize{
  color: #86868B;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

#uploadFileModal .modal-content{
  text-align: center;
  border-radius: 0px;
  border: none;
}

#uploadFileModal .modal-content p{
  color: #86868B;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

#uploadFileModal .modal-content input{
  margin-bottom: 1rem;
}

#uploadFileModal .modal-content #chooseFileButton{
  background-color: white;
  color: #1974d2;
  border-radius: 0px;
  border: 2px solid #1974d2;
  padding: 0.75rem 1rem;
  width: 100%;
  margin-bottom: 0.5rem;
}

#uploadFileModal .modal-content button{
  background-color: #1974d2;
  border-radius: 0px;
  border: 2px solid #1974d2;
  padding: 0.75rem 1rem;
  width: 100%;
}

#changeLanguageModal .modal-header{
  border-bottom: none;
}

#changeLanguageModal .languageCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  border: 2px solid transparent;
}

#changeLanguageModal .languageCard.active {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  border: 2px solid #6b64ff;
}

#changeLanguageModal p {
  margin-bottom: 0px;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalHeader svg{
  color: #111111;
  height: 24px;
  width: auto;
  cursor: pointer;
}

#modalHeaderBackBtn {
  color: #322e87;
  height: 27.5px;
  margin-top: -3px;
  margin-left: -7px;
  margin-right: -5px;
}

.modalBody {
  padding: 0.75rem 1rem;
}

.modal-content {
  border-radius: 1rem;
  border: none;
}

.modal-body {
  padding: 1rem;
}

.modal-body h1{
  color: #6b64ff;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0px;
}

.modal-body h2{
  color: #322e87;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.modal-body h3{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1rem;
}

#servicesPage .servicesRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#servicesPage .serviceCard {
  width: 24%;
  background-color: white;
  text-align: center;
  padding: 1rem;
}

#servicesPage .serviceCard svg{
  width: 100px;
  height: auto;
  margin-bottom: 0.5rem;
}

#servicesPage .serviceCard h3{
  color: #1C2E4A;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

#servicesPage .serviceCard p{
  color: #111111;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.loadingScreenDiv {
  margin-top: -4.5rem;
}

#completeOrderModalBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

#completeOrderModalInvoiceNumbers {
  width: 48%;
}

#completeOrderModalInvoiceUpload {
  width: 48%;
}

.form-control:focus {
  box-shadow: none;
  border-color: #6b64ff;
}

.accessRequestContainer {
  border-radius: 10px;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  padding: 1rem;
  margin-bottom: 1rem;
}

.accessRequestContainer p {
  margin-bottom: 0px !important;
}

.css-16xfy0z-control {
  border-radius: 10px !important;
}

.itemHistoryItem {
  border-radius: 10px;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.itemHistoryItem p {
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none !important;
  }

  #loginPage .loginContainer img {
    height: 40px !important;
    margin-bottom: 3rem;
  }

  #loginPageLanguageToggle:hover {
    color: #868686
  }

  #accountPageCompanyCard {
    width: 100%;
  }

  #accountPageCompanyImgMain {
    display: none;
  }

  #accountPageCompanyCardTextContainer h3 {
    margin-bottom: 0.5rem;
  }

  #accountPageCompanyCardTextContainer {
    padding-left: 0px;
    padding-right: 0px;
  }

  #accountPageCompanyCardTextContainer p {
    margin-bottom: 0px !important;
  }

  #accountPageCompanyCardMobileContainer {
    display: flex;
    flex-direction: row;
  }

  #accountPageCompanyImgMobile {
    margin-right: 0.5rem;
  }

  #companyPageCountryCardTextContainer p {
    margin-bottom: 0px !important;
  }

  .branchMemberCard {
    padding: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .branchMemberCard p {
    width: 40%;
    padding-right: 0.5rem;
  }

  #branchMemberCardButtons {
    width: 60%;
    justify-content: space-between;
  }

  #branchMemberCardButtons button {
    font-size: 14px;
  }

  #branchMemberCardButtons div {
    margin-right: 0px !important;
  }

  #myNavbar {
    padding: 1rem
  }

  #mobileSubNavbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #mobileSubNavbar a {
    text-decoration: none;
    color: #868686;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  #acceptOrderBtn {
    width: 25%;
  }

  .orderItemName {
    width: 33.3%;
  }

  .orderItemBrand {
    width: 33.3%;
  }

  .orderItemStatus {
    width: 33.3%;
  }

  .orderItemQuantity {
    width: 33.3%;
  }

  nav.navbar {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  nav.navbar a.navbar-brand {
    padding-left: 0px;
  }

  nav.navbar li {
    margin-top: 1rem;
    text-align: center;
  }

  nav.navbar li p{
    margin-bottom: 0px;
  }

  nav.navbar .nav-item svg{
    display: none;
  }

  .collapse.show.navbar-collapse {
    padding-bottom: 0.5rem;
  }

  nav.navbar a#registerButton {
    margin-left: 0px;
    margin-top: 1rem;
  }

  .navbar-toggler {
    border: none;
  }

  #navbarToggler {
    color: white;
    width: 30px;
    height: auto;
  }

  #pageContainer {
    margin-top: 1rem;
    text-align: left;
    padding-top: 0px;
    /* margin-top: 0px;
    position: absolute;
    bottom: 20px */
  }

  #pageContainer p {
    margin-bottom: 1.5rem;
  }

  #pageContainer #heroButtonsContainer {
    display: block;
  }

  #loginPage .loginContainer{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  #registerSection form .col-8, #registerSection form .col-4 {
    flex-basis: auto;
    width: 100%;
  }

  #registerSection form button {
    width: 100%;
  }

  #howWeDoItSection {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #howWeDoItSection h1 {
    margin-bottom: 0.5rem;
  }

  #howWeDoItSection .col{
    flex-basis: auto;
  }

  #howWeDoItSection #outreachFormInputRow {
    display: block;
  }

  #howWeDoItSection input {
    width: 100%;
  }

  #howWeDoItSection h1 {
    margin-bottom: 2rem;
  }

  #industriesSection {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #industriesSection .industriesCard {
    width: 180px;
  }

  #ourTeamSection {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #ourTeamSection h1 {
    margin-bottom: 0.5rem;
  }

  #contactSection {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }

  #contactSection h1 {
    margin-bottom: 0.5rem;
  }

  #ourTeamSection .mobileTeamMembersRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #ourTeamSection .teamMemberContainer {
    margin-bottom: 0.5rem;
    margin-right: 0px;
    width: 48%;
  }

  #ourTeamSection .teamMemberContainer img {
    width: 99%;
    height: auto !important;
  }

  #ourTeamSection .teamMemberContainer p {
    display: none;
  }

  #contactSection input {
    width: 100%;
  }

  #contactSection input[type=submit i], #howWeDoItSection input[type=submit i] {
    width: 100%;
  }

  #termsOfServiceContainer, #privacyPolicyContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  #servicesPage .servicesRow {
    flex-direction: column;
  }

  #servicesPage .serviceCard {
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
  }

  #servicesPage .serviceCard .serviceTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  #servicesPage .serviceCard svg {
    margin-bottom: 0px;
    margin-right: 1rem;
  }

  #helpPage .helpPageArticle h3{
    text-align: left;
  }
  
  footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .loadingScreenDiv {
    margin-top: -5.1rem;
  }

  #completeOrderModalBody {
    display: flex;
    flex-direction: column;
  }

  #completeOrderModalInvoiceNumbers {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  #completeOrderModalInvoiceUpload {
    width: 100%;
  }

  #completeOrderModalInvoiceUpload img {
    width: 47%;
    height: auto !important;
  }
}

@media only screen and (min-width: 768px) {
  #navbarToggler {
    display: none;
  }

  .mobileTeamMembersRow {
    display: none;
  }

  .hideOnDesktop {
    display: none;
  }

  nav.navbar .nav-item span{
    display: none;
  }
}